import React from 'react';
import type {FC, ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {UserStore} from 'src/store/user';
import {inject, observer} from 'mobx-react';


interface GuestGuardProps {
  children?: ReactNode;
  userStore?: UserStore;
}

const GuestGuard: FC<GuestGuardProps> = ({children, userStore}) => {
  const {isAuthenticated, user} = userStore;

  if (isAuthenticated && user && user.isEmailVerified) {
    return <Redirect to="/app/settings" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default inject("userStore")(observer(GuestGuard));
