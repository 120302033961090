import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import {firebaseConfig} from 'src/config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();

export default firebase;
