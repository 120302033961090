import React from 'react';
import type {FC} from 'react';
import "./styles.scss";
import clsx from 'clsx';

export interface SectionProps {
    children?: any;
    id: string;
    className?: string;
}

const Section: FC<SectionProps> = ({children, id, className}: SectionProps) => {

    return (
        <div className={clsx("section", className)} id={id}>
            <div className="content-wrapper">
                {children}
            </div>
        </div>
    );
};

export default Section;