import React, {useEffect} from 'react';
import type {FC} from 'react';
import Logo from 'src/components/shared/logo';
import {Link} from 'react-router-dom';
import "./styles.scss";
import Button from 'src/components/website/button';
import {Lock} from "react-feather";
import clsx from 'clsx';
import InfoBar from './infobar';

export interface TopbarProps {
    sticky?: boolean;
    overlay?: boolean;
}

const TopBar: FC<TopbarProps> = ({overlay, sticky}: TopbarProps) => {

    useEffect(() => {
        //On mount
        if (sticky && !overlay)
            document.body.classList.add("topbar-fixed");

        //On unmount
        return () => {
            document.body.classList.remove("topbar-fixed");
        }
    });

    return (
        <div className={clsx("topbar", {sticky, overlay})}>
            <InfoBar />
            <div className="topbar-content">
                <Link className="logo-link" to="/"><Logo full={true} /></Link>
                <div className="buttons">
                    <Button className="login" link="/sign-in" label="Login" variant="secondary" />
                    <Button className="sign-up" link="/register" label="Sign Up" />
                    <Link className="login-mobile" to="/sign-in"><Lock size="16px" /></Link>
                </div>
            </div>
        </div>
    );
};

export default TopBar;