import React from 'react';
import type { FC } from 'react';
import "./styles.scss";
import Section from 'src/components/website/section';

const Faqs: FC = () => {

  return (
    <Section id="faqs" className="faqs">
      <h1>
        Frequently asked questions
      </h1>
      <div className="section-row">
        <div className="section-column">
          <div className="info">
            <h2>How exactly do I set everything up?</h2>
            <p>After signing up, you gain access to our training videos and material, which provides step by step instructions on what you need to do.</p>
          </div>
        </div>
        <div className="section-column">
          <div className="info">
            <h2>What is the minimum amount I can trade with?</h2>
            <p>$100. However, we recommend that you trade with a larger amount due to the fixed fees which you will incur.</p>
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="section-column">
          <div className="info">
            <h2>How long does it take to complete one trade?</h2>
            <p>The USD you send overseas usually reflects on the same day it is sent. Provided all custom trade criteria are met, it would be normal to receive your ZAR back the next business day.</p>
          </div>
        </div>
        <div className="section-column">
          <div className="info">
            <h2>How many times can I trade?</h2>
            <p>South Africans are only allowed to send R1m a year freely before needing to apply for larger allowances (our forex provider will be able to assist you with this).</p>
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="section-column">
          <div className="info">
            <h2>What are some of the risks involved?</h2>
            <p>Cryptocurrency is priced in US dollars. If the Rand strengthens whilst you are holding US dollars, you will lose in Rands (decreasing your profits). There also other risks, but these are managed. They include brief price movement risk, the inability to trade should a provider temporarily go down for maintenance, incorrectly paying your USD to the wrong account, and exchange security risks.</p>
          </div>
        </div>
        <div className="section-column">
          <div className="info">
            <h2>What are the all the fees involved?</h2>
            <p>R500 fixed per trade. Variable fees approximately range between 1.1% and 1.2% of trade value. The breakdown is as follows:</p>
            <br />
            <ul>
              <li>Currencies Direct: R500 fixed per trade. 0.4% variable.</li>
              <li>Binance: 0.075% variable. </li>
              <li>VALR: 0.2% variable if you take market (set by default). 0.09% if you make.</li>
              <li>Bitwise: 0.5% variable.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="section-column">
          <div className="info">
            <h2>What custom trade functionality is available?</h2>
            <p>This is not required, but you can specify the arbritrage level at which you would like your trade to execute. You can also opt to be a market maker, instead of a taker.</p>
          </div>
        </div>
        <div className="section-column">
          <div className="info">
            {/* <h2>How does Bitwise make money?</h2>
            <p>Firstly, we make money when you use our referral links when signing up to the various platforms required. Secondly, we can make money by facilitating the trade.</p> */}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Faqs;