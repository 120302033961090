import React, {useState} from 'react';
import type {FC} from 'react';
import "./styles.scss";
import Section from 'src/components/website/section';
import Button from 'src/components/website/button';

const Register: FC = () => {

  const [email, setEmail] = useState("");

  return (
    <Section id="register" className="register">
      <h1>
        Get started today
      </h1>
      <h4>
        Register to use the Bitwise platfrom. Its free to sign up!
      </h4>
      <div className="section-row">
        <div className="section-column">
          <div className="register-form">
            <input type="text" id="email" name="email" className="register-email" placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} />
            <Button className="sign-up" link={`/register?email=${email}`} label="Sign Up" variant="outlined" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Register;