import React from 'react';
import type {FC} from 'react';
import "./styles.scss";
import {ArrowDownRight} from "react-feather";
import Button from 'src/components/website/button';
import VideoPlayer from 'src/components/shared/videoPlayer';

const Intro: FC = () => {

    return (
        <div className="intro" id="howitworks">
            <div className="intro-topbar">
                <div className="content-wrapper">
                    <Button href="#howitworks" className="title" variant="secondary">
                        <ArrowDownRight /><h4>How it works</h4>
                    </Button>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="youtube-container">
                    <iframe width="100%" height="600px" src="https://www.youtube.com/embed/ib9qlLMO_Rg" frameBorder="0" className="youtube-video" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default Intro;