import React from 'react';
import type {FC} from 'react';
import "./styles.scss";
import Section from 'src/components/website/section';
import {Activity, RefreshCw, Percent, Tag, ArrowUpRight, Bell} from "react-feather";

const About: FC = () => {

    return (
        <Section id="about" className="about">
            <h1>
                Why use bitwise
            </h1>
            <div className="section-row">
                <div className="section-column">
                    <div className="info">
                        <RefreshCw size={50} />
                        <h2>Automated</h2>
                        <p>Bitwise is fully automated and performs the buying, selling and withdrawals on each exchange for you.</p>
                    </div>
                </div>
                <div className="section-column">
                    <div className="info">
                        <ArrowUpRight size={50} />
                        <h2>Customisable</h2>
                        <p>Change the default trading parameters of the system and customise how the bot executes trades.</p>
                    </div>
                </div>
                <div className="section-column">
                    <div className="info">
                        <Bell size={50} />
                        <h2>Notifications</h2>
                        <p>Receive notifications when the arbitrage is at levels of interest.</p>
                    </div>
                </div>

                <div className="section-column">
                    <div className="info">
                        <Activity size={50} />
                        <h2>Low Risk</h2>
                        <p>Bitwise facilitates the trade using the fastest tokens, at the right time, resulting in minimal slippage and price movement risk.</p>
                    </div>
                </div>
                <div className="section-column">
                    <div className="info">
                        <Percent size={50} />
                        <h2>Low Fees</h2>
                        <p>It's free to sign-up, and Bitwise charges a fixed 0.5% per trade for using the platform.</p>
                    </div>
                </div>
                <div className="section-column">
                    <div className="info">
                        <Tag size={50} />
                        <h2>Discounted Rates</h2>
                        <p>Bitwise customers get discounted forex rates with our forex partner.</p>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default About;