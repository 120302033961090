import clsx from "clsx";
import React from "react";
import {Link} from "react-router-dom";
import "./styles.scss";

export interface ButtonProps {
    link?: string;
    label?: string;
    className?: string;
    variant?: "primary" | "secondary" | "outlined";
    href?: string;
    onClick?: () => any;
    children?: any;
}

const Button = (props: ButtonProps) => {

    const classes = clsx(props.className, "bw_button", props.variant || "primary");

    if (props.link)
        return <Link className={classes} to={props.link}>{props.children || props.label}</Link>;

    return <a className={classes} onClick={props.onClick} href={props.href}>{props.children || props.label}</a>
};

export default Button;