import {UserStore} from "./user";
import SettingsStore from "./settings";
import MarketDataStore from "./marketData";

export class RootStore {

    public userStore: UserStore;
    public settingsStore: SettingsStore;
    public marketDataStore: MarketDataStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.settingsStore = new SettingsStore(this);
        this.marketDataStore = new MarketDataStore(this);
    }
}