import {observable, action, computed} from 'mobx';
import {database} from 'src/lib/firebase';
import {BankDetails} from 'src/types/bankDetails';
import { Notifications } from 'src/types/notifications';
import { TradingParameters } from 'src/types/tradingParameters';
import {ApiKeys} from 'src/types/userSettings';
import {WithdrawalAddresses} from 'src/types/withdrawalAddresses';
import {RootStore} from '..';

class SettingsStore {
    private _rootStore: RootStore;

    @observable public apiKeys: ApiKeys;
    @observable public apiSecrets: ApiKeys;
    @observable public isLoading: boolean = true;
    @observable public withdrawalAddresses: WithdrawalAddresses;
    @observable public bankDetails: BankDetails;
    @observable public tradingParameters: TradingParameters;
    @observable public notifications: Notifications;


    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;

        this.apiKeys = {
            binance: "",
            valr: "",
        };

        this.apiSecrets = {
            binance: "",
            valr: "",
        };

        this.withdrawalAddresses = {
            tusdAddress: "",
            tusdMemo: "",
            xrpAddress: "",
            xrpMemo: ""
        };

        this.bankDetails = {
            autoWithdrawal: false,
            quickWithdrawal: false
        };

        this.tradingParameters = {
            customEnabled: false,
            maker: false,
            noTrades: 3,
            targetLevel: 0
        };

        this.notifications = {
            enabled: false
        };
    }

    @action public updateNotifications = async (enabled: boolean, level?: number) => {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        await database.ref(`users/${userId}/settings/notifications`).update({
            enabled,
            level
        });
    }

    @action public async loadNotifications() {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        this.isLoading = true;

        database.ref(`users/${userId}/settings/notifications`).on("value", async (data) => {
            if (data.exists())
                this.notifications = data.val();
        });

        this.isLoading = false;
    }

    @action public updateTradingParameters = async (customEnabled: boolean, maker: boolean, noTrades: number, targetLevel?: number) => {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        await database.ref(`users/${userId}/settings/tradingParameters`).update({
            customEnabled,
            targetLevel,
            maker,
            noTrades
        });
    }

    @action public async loadTradingParameters() {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        this.isLoading = true;

        database.ref(`users/${userId}/settings/tradingParameters`).on("value", async (data) => {
            if (data.exists())
                this.tradingParameters = data.val();
        });

        this.isLoading = false;
    }

    @action public updateBankDetails = async (autoWithdrawal: boolean, last4Digits: number, quickWithdrawal?: boolean) => {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        await database.ref(`users/${userId}/settings/bankDetails`).update({
            autoWithdrawal,
            last4Digits: autoWithdrawal ? last4Digits : "",
            quickWithdrawal
        });
    }

    @action public async loadBankDetails() {
        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        this.isLoading = true;

        database.ref(`users/${userId}/settings/bankDetails`).on("value", async (data) => {
            if (data.exists())
                this.bankDetails = data.val();
        });

        this.isLoading = false;
    }

    @action public async loadApiKeys() {

        const userId = this._rootStore.userStore?.user?.id;

        if (!userId)
            return;

        this.isLoading = true;

        database.ref(`users/${userId}/settings/apiKeys`).on("value", async (u) => {

            if (!u.exists()) {
                this.isLoading = false;
                return;
            }

            const apiKeys = u.val();
            this.apiKeys = {
                binance: apiKeys.binance,
                valr: apiKeys.valr
            };
            this.isLoading = false;
        });

        database.ref(`users/${userId}/settings/addresses`).on("value", async (data) => {
            if (data.exists())
                this.withdrawalAddresses = data.val();
        });
    }

    @action public async removeApiKey(exchange: string) {
        const userId = this._rootStore.userStore?.user?.id;
        if (!userId)
            return;

        this.isLoading = true;

        const key = exchange.toLowerCase();

        this.apiKeys[key] = "";
        this.apiSecrets[key] = "";

        database.ref()

        await database.ref(`users/${userId}/settings/apiKeys`).update({
            [key]: "",
        });

        await database.ref(`users/${userId}/settings/apiSecrets`).update({
            [key]: "",
        });
        
        this.isLoading = false;
    }

    @action public async configureApiKey(exchange: string, apiKey: string, secretKey: string) {
        const userId = this._rootStore.userStore?.user?.id;
        if (!userId)
            return;

        this.isLoading = true;

        const key = exchange.toLowerCase();

        this.apiKeys[key] = apiKey;

        const p1 = database.ref(`users/${userId}/settings/apiKeys`).update({
            [key]: apiKey,
        });

        const p2 = database.ref(`users/${userId}/settings/apiSecrets`).update({
            [key]: secretKey,
        });

        await Promise.all([p1, p2]);

        this.isLoading = false;
    }
}

export default SettingsStore;