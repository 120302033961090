import React from 'react';
import type {FC} from 'react';

interface LogoProps {
  full?: boolean;
  white?: boolean;
}

const Logo: FC<LogoProps> = (props) => {

  let name = "logo";

  if (props.white) {
    name += "-full-white";
  } else if (props.full) {
    name += "-full";
  }

  return (
    <img
      alt="Logo"
      src={`/static/${name}.svg`}
    />
  );
}

export default Logo;
