import {observable, action} from 'mobx';
import {database} from 'src/lib/firebase';
import {MarketData} from 'src/types/marketData';
import {RootStore} from '..';

class MarketDataStore {
    private _rootStore: RootStore;

    @observable public marketData: MarketData;
    @observable public isLoading: boolean;


    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;

        this.marketData = {
            btcusd: this.getStoredValue("btcusd"),
            btczar: this.getStoredValue("btczar"),
            currentarb: this.getStoredValue("currentarb"),
            usdzar: this.getStoredValue("usdzar"),
        }
    }

    private getStoredValue = (key: string): number => Number(localStorage.getItem(key) || 0);
    private setStoredValue = (key: string, value: number) => localStorage.setItem(key, value.toString());

    @action public async load() {
        this.isLoading = true;

        database.ref(`marketData`).on("value", async (data) => {
            if (data.exists()) {
                this.marketData = data.val();

                Object.keys(this.marketData).forEach(k => {
                    this.setStoredValue(k, this.marketData[k]);
                });
            }
        });

        this.isLoading = false;
    }
}

export default MarketDataStore;