import React from 'react';
import type {FC, ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {UserStore} from 'src/store/user';
import {inject, observer} from 'mobx-react';
import SplashScreen from '../splashScreen';

interface AuthGuardProps {
  children?: ReactNode;
  userStore?: UserStore;
}

const AuthGuard: FC<AuthGuardProps> = ({children, userStore}) => {
  const {isAuthenticated, isInitialized, user} = userStore;

  if (!isInitialized) {
    return <SplashScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (user && !user.isEmailVerified) {
    return <Redirect to="/unverified-email" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default inject("userStore")(observer(AuthGuard));
