import {observable, action} from 'mobx';
import {User} from 'src/types/user';
import {RootStore} from '..';
import firebase, {database, auth} from 'src/lib/firebase';
import {RegisterUser} from 'src/types/registerUser';
import {parse} from "query-string";
import wait from 'src/utils/wait';


export class UserStore {

    private _rootStore: RootStore;

    @observable public isAuthenticated: boolean = false;
    @observable public isInitialized: boolean = false;
    @observable public userDataLoaded: boolean = false;
    @observable public user: User = null;
    @observable public newUser: RegisterUser = null;
    @observable public hasTriedVerification: boolean = false;

    //Password Reset
    @observable public isPasswordResetInitialized: boolean = false;
    @observable public isPasswordResetCodeValid: boolean = true;

    //Email Verification
    @observable public isEmailVerificationInitialized: boolean = false;
    @observable public isVerifyEmailCodeValid: boolean = true;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;

        this.newUser = {
            email: "",
            firstName: "",
            lastName: "",
            password: ""
        };

        auth.onAuthStateChanged(async (firebaseUser) => {
            if (firebaseUser) {

                //Configure firebase auth to only last for the length of the browser session
                auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

                this.user = {
                    id: firebaseUser.uid,
                    avatar: firebaseUser.photoURL,
                    email: firebaseUser.email,
                    displayName: firebaseUser.displayName,
                    isEmailVerified: firebaseUser.emailVerified,
                    hasReceivedEmailVerification: false,
                };

                this.setIsAuthenticated(true);

                database.ref(`users/${firebaseUser.uid}/profile`).on("value", async (session) => {

                    if (session.exists()) {
                        const {firstName, lastName, hasReceivedEmailVerification} = session.val();
                        await this.updateUser({firstName, lastName, hasReceivedEmailVerification}, false);
                    }
                    else {
                        this.user.firstName = this.newUser.firstName;
                        this.user.lastName = this.newUser.lastName;
                        this.user.displayName = `${this.user.firstName} ${this.user.lastName}`
                        //Create a new user session entry in firebase DB
                        await this.persistUser();
                    }

                    if (!firebaseUser.emailVerified) {

                        if (this.hasTriedVerification)
                            return;
                        // this.isInitialized = true;
                        // this.userDataLoaded = true;
                        this.hasTriedVerification = true;
                        await this.sendEmailVerification();
                        await wait(1000);
                        console.log("logging out user");
                        await auth.signOut();
                        return;
                    } 

                    //TODO: Rather load notifications which are set by server based on data
                    //Load API keys to determine if alerts should display
                    await this._rootStore.settingsStore.loadApiKeys();
                    await this._rootStore.settingsStore.loadBankDetails();
                    await this._rootStore.settingsStore.loadNotifications();
                    await this._rootStore.settingsStore.loadTradingParameters();

                    this.isInitialized = true;
                    this.userDataLoaded = true;

                });
            } else {
                this.user = null;
                this.setIsAuthenticated(false);
                this.isInitialized = true;
            }
        });
    }


    @action private persistUserSession = async (): Promise<void> => {
        if (!this.user) {
            return;
        }
        await database.ref(`users/${this.user.id}/profile`).update({
            hasReceivedEmailVerification: this.user.hasReceivedEmailVerification,
            firstName: this.user.firstName,
            lastName: this.user.lastName
        });
    }

    @action private persistUserProfile = async (): Promise<void> => {
        await auth.currentUser.updateProfile({
            displayName: this.user.displayName,
        });
    }

    @action public persistUser = async () => {
        await this.persistUserProfile();
        await this.persistUserSession();
    }

    @action public updateUser = async (userProperties: Partial<User>, persist: boolean = true) => {
        this.user = {
            ...this.user,
            ...userProperties,
        };

        this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;

        if (persist)
            await this.persistUser();
    };

    @action public setIsAuthenticated = (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
    };

    @action public logout = async (): Promise<void> => {
        this.isInitialized = false;
        this.isAuthenticated = false;
        await auth.signOut();
    };

    @action public signInWithGoogle = (): Promise<any> => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return auth.signInWithPopup(provider);
    };

    @action public registerUser = async (newUser: RegisterUser) => {
        this.newUser = newUser;
        await auth.createUserWithEmailAndPassword(newUser.email, newUser.password);
    }

    @action public createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        await auth.createUserWithEmailAndPassword(email, password);
    };

    @action public signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        await auth.signInWithEmailAndPassword(email, password);
    };

    @action public sendEmailVerification = async () => {
        if (!this.user.hasReceivedEmailVerification) {
            try {
                console.log("sending email verification");
                this.user.hasReceivedEmailVerification = true;
                await auth.currentUser.sendEmailVerification();
            } catch (e) {
                console.log("Error with verification");
                console.log(e);
                this.user.hasReceivedEmailVerification = false;
            }
            console.log("saving user details");
            await this.persistUserSession();
        }
    }

    @action public updatePassword = async (password: string) => {
        await auth.currentUser.updatePassword(password);
    }

    @action public sendPasswordResetEmail = async (email: string) => {
        await auth.sendPasswordResetEmail(email);
    }

    @action public validatePasswordResetCode = async () => {
        const qs = parse(window.location.search);
        this.isPasswordResetInitialized = false;

        if (qs.code && typeof qs.code === "string") {
            try {
                await auth.verifyPasswordResetCode(qs.code);
                this.isPasswordResetCodeValid = true;
            } catch (e) {
                this.isPasswordResetCodeValid = false;
            }
        }

        this.isPasswordResetInitialized = true;
    }

    @action public confirmPasswordReset = async (password: string) => {
        const qs = parse(window.location.search);

        if (qs.code && typeof qs.code === "string") {
            await auth.confirmPasswordReset(qs.code, password);
        }
    }

    @action public verifiyEmailAddress = async () => {
        const qs = parse(window.location.search);
        this.isEmailVerificationInitialized = false;
        if (qs.code && typeof qs.code === "string") {
            try {
                await auth.applyActionCode(qs.code);
                this.isVerifyEmailCodeValid = true;
                await database.ref(`users/${this.user.id}/profile`).update({
                    isEmailVerified: true
                });
            } catch (e) {
                this.isVerifyEmailCodeValid = false;
            }
        }
        this.isEmailVerificationInitialized = true;
    }

}