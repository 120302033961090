import React, {useEffect} from 'react';
import type {FC} from 'react';
import "./styles.scss";
import {inject, observer} from 'mobx-react';
import MarketDataStore from 'src/store/marketData';

interface InfoBarProps {
    marketDataStore?: MarketDataStore;
}

const InfoBar: FC<InfoBarProps> = ({marketDataStore}) => {

    useEffect(() => {
        marketDataStore.load();
    }, []);

    const {marketData} = marketDataStore;

    return (
        <div className="infobar">
            <div className="infobar-content">
                <div className="info-text"><div className="title">Arbitrage</div><div className="text">{formatNumber(marketData.currentarb, 2)}%</div></div>
                <div className="spacer"></div>
                <div className="info-text"><div className="title">BTC/USD</div><div className="text">{formatNumber(marketData.btcusd, 2)}</div></div>
                <div className="info-text"><div className="title">BTC/ZAR</div><div className="text">{formatNumber(marketData.btczar)}</div></div>
                <div className="info-text"><div className="title">USD/ZAR</div><div className="text">{formatNumber(marketData.usdzar, 2)}</div></div>
            </div>
        </div>
    );
};

const formatNumber = (n: number, p?: number): string => !!p ? n.toFixed(p).replace(/\d(?=(\d{3})+\.)/g, '$&,') : n.toLocaleString();

export default inject("marketDataStore")(observer(InfoBar));