import React, {useEffect} from 'react';
import type {FC} from 'react';
import Page from 'src/components/website/page';
import Hero from './hero';
import "./styles.scss";
import Intro from './intro';
import About from './about';
import Testimonials from './testimonials';
import Faqs from './faqs';
import Register from './register';
const HomeView: FC = () => {

  return (
    <Page
      title="Home"
      className="landing-page-content"
    >
      <Hero />
      <Intro />
      <About />
      <Register />
      <Faqs />
      {/* <Testimonials /> */}
    </Page>
  );
};

export default HomeView;