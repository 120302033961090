import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'src/__mocks__';
import 'src/assets/css/prism.css';

import "./fonts.scss"

import React from 'react';
import ReactDOM from 'react-dom';
import {enableES5} from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import {SettingsProvider} from 'src/contexts/settingsContext';
import App from 'src/app';
import {Provider as StateProvider} from "mobx-react"
import {RootStore} from './store';

enableES5();

const store = new RootStore();

ReactDOM.render(
  <StateProvider {...store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </StateProvider>,
  document.getElementById('root')
);

serviceWorker.register();
