import React from 'react';
import type {FC} from 'react';
import "./styles.scss";

const Hero: FC = () => {

    return (
        <div className="hero" id="hero">
            <div className="hero-squares" />
            <div className="content-wrapper">
                <div className="hero-header-image">
                    <img src="/static/images/hero-header-text.svg" />
                </div>
                <div className="hero-blurb">
                    Make money buying cryptocurrency offshore and selling it locally with low fees and minimal risk.
                </div>
                <div className="hero-partners">
                    <img src="/static/images/trust-token-black.svg" />
                    <img src="/static/images/binance-black.svg" />
                    <img src="/static/images/valr-black.svg" />
                </div>
            </div>
        </div>
    );
};

export default Hero;