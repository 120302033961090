import React from 'react';
import type {FC} from 'react';
import "./styles.scss";
import {Mail, MapPin, Phone} from "react-feather";

const Footer: FC = () => {

    return (
        <div className="footer" id="footer">
            <div className="content-wrapper">
                <div className="footer-content">
                    <div className="footer-column copywrite">
                        <div className="copywrite-content">
                            <img className="logo" src="/static/logo-full-white.svg"></img>
                            <span>© 2020 Bitwise Crypto (Pty) Ltd</span>
                        </div>
                    </div>
                    <div className="footer-company-details">
                        <div className="footer-column about">
                            <h4>About</h4>
                            <a href="/#faqs">FAQs</a>
                            {/* <a href="/fees">Fees</a>
                            <a href="#">Careers</a> */}
                        </div>
                        <div className="footer-column legal">
                            <h4>Legal</h4>
                            <a href="/terms">Terms</a>
                            <a href="/privacy">Privacy</a>
                        </div>
                        <div className="footer-column contact">
                            <h4>Contact</h4>
                            <div className="contact-info"><Mail size="14px" />support@bitwisecrypto.co.za</div>
                            {/* <div className="contact-info"><MapPin size="14px" />3 Albion Road, Rondebosch, 7701</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;