import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import AdminLayout from 'src/layouts/admin';
import MainLayout from 'src/layouts/website';
import HomeView from 'src/views/website/home';
import LoadingScreen from 'src/components/admin/loadingScreen';
import AuthGuard from 'src/components/admin/guards/authGuard';
import GuestGuard from 'src/components/admin/guards/guestGuard';

type RouteList = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  layoutProps?: any;
  component?: any;
  routes?: RouteList;
}[];

export const renderRoutes = (routeList: RouteList = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routeList.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout {...route.layoutProps}>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: RouteList = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/admin/errors/notFound'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ['/login', '/signin', '/sign-in'],
    component: lazy(() => import('src/views/admin/auth/login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/admin/auth/register'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/account-actions',
    component: lazy(() => import('src/views/admin/auth/accountActions'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/admin/auth/forgotPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/admin/auth/accountActions/resetPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/unverified-email',
    component: lazy(() => import('src/views/admin/auth/unverifiedEmail'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verify-email',
    component: lazy(() => import('src/views/admin/auth/accountActions/verifyEmail'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('src/views/admin/settings'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/admin/account'))
      },
      {
        exact: true,
        path: '/app/training',
        component: lazy(() => import('src/views/admin/training'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/settings" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        layout: MainLayout,
        layoutProps: {topbar: "overlay"},
        component: HomeView,
      },
      {
        exact: true,
        layout: MainLayout,
        path: '/privacy',
        component: lazy(() => import('src/views/website/legal/privacy'))
      },
      {
        exact: true,
        layout: MainLayout,
        path: '/terms',
        component: lazy(() => import('src/views/website/legal/terms'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
