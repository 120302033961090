import React, {useEffect} from 'react';
import type {FC} from 'react';
import {
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import type {Theme} from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '300px',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={280}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
