import React, {useEffect} from 'react';
import type {FC} from 'react';
import TopBar from 'src/components/website/topbar';
import Footer from 'src/components/website/footer';
import "./styles.scss";

interface MainLayoutProps {
  children?: any;
  topbar?: "fixed" | "overlay" | "sticky";
}


const MainLayout: FC<MainLayoutProps> = ({children, topbar}) => {

  const onScroll = (e) => {
    const div = (e.target as HTMLElement);
    const prevScrollTop = (window as any).prevScrollTop;
    const scrollingDown = prevScrollTop < div.scrollTop;
    const scrollingUp = !scrollingDown;

    (window as any).prevScrollTop = div.scrollTop;
    if (scrollingDown && div.scrollTop > 0 || scrollingUp && div.scrollTop > 20) {
      document.body.classList.add("scrolling");
    } else {
      document.body.classList.remove("scrolling");
    }
  };

  return (
    <div className="site-wrapper" onScroll={onScroll}>
      <TopBar sticky={topbar !== "fixed"} overlay={topbar === "overlay"} />
      <div className="content-container">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
