import React, {useState} from 'react';
import type {FC, ReactNode} from 'react';
import {Box, Container, makeStyles, Typography} from '@material-ui/core';
import type {Theme} from 'src/theme';
import NavBar from './navbar';
import TopBar from './topbar';
import {UserStore} from 'src/store/user';
import {inject, observer} from 'mobx-react';
import LoadingScreen from 'src/components/admin/loadingScreen';
import {Alert} from "@material-ui/lab";
import SettingsStore from 'src/store/settings';
import {Link} from 'react-router-dom';

interface DashboardLayoutProps {
  children?: ReactNode;
  userStore?: UserStore;
  settingsStore?: SettingsStore;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const AdminLayout: FC<DashboardLayoutProps> = ({children, userStore, settingsStore}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const {isInitialized} = userStore;
  const {withdrawalAddresses, apiKeys} = settingsStore;

  const apiKeysIncomplete = !apiKeys.binance || !apiKeys.valr;
  const apiKeysInvalid = !apiKeysIncomplete && (!withdrawalAddresses.tusdAddress || !withdrawalAddresses.xrpAddress);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>

          <div className={classes.content}>
            {isInitialized ?
              <Container maxWidth="lg">
                {apiKeysIncomplete &&
                  <Box mt={3} mb={1}>
                    <Alert severity="info">Please configure your API Keys to activate your account</Alert>
                  </Box>
                }
                {apiKeysInvalid &&
                  <Box mt={3} mb={1}>
                    <Alert severity="error">Your account is currently inactive due to one or more invalid API keys</Alert>
                  </Box>
                }
                {children}
              </Container>
              : <LoadingScreen />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("userStore", "settingsStore")(observer(AdminLayout));
